<template>
    <div>
        <HeaderBg :img="img" title="نقاط الولاء" />
        <header class="admin_content_header">
            <div class="filter">
                <!-- <select>
                    <option value="" selected disabled>المدينة</option>
                </select> -->
            </div>
            <div class="search">
                <!-- <input type="text" placeholder="البحث برقم الفرع"> -->
            </div>
            <router-link to="/store-admin/loyalty/add-loyalty"> + تعديل نقاط ولاء </router-link>
            
        </header>
        <RequestSpinner v-if="loadingRequest == true" />
        <div class="main_table" v-if="loadingRequest == false">
            <table width="100%">
                <thead>
                    <tr>
                        <th> الريال الى نقاط ولاء </th>
                        <th> اعلي نقاط ولاء </th>
                        <th> أقل نفاط ولاء </th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td> {{loyalty.sar_to_loyalty_points}} </td>
                        <td> {{loyalty.maximum_loyalty_points}} </td>
                        <td> {{loyalty.minimum_loyalty_points}} </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <!-- <div class="pagination mt-5" v-if="cities.length >= 1">
            <b-pagination-nav align="center" :link-gen="linkGen" :number-of-pages="pages" use-router v-model="currentPage"></b-pagination-nav>
        </div> -->
    </div>
</template>
<script>
import HeaderBg from '../../global/HeaderBg/HeaderBg';
import RequestSpinner from '../../global/loadingSpinners/RequestSpinner';
import Request from '../../../services/Request';

export default {
    name: 'Loyalty',
    components: {HeaderBg, RequestSpinner},
    data(){
        return{
            loadingRequest: true,
            img: require('../../../assets/images/saleWhite.svg'),
            loyalty: {},
        }
    },
    mounted(){
        this.getLoyalty();
    },
    methods:{
        getLoyalty(){
            Request.fetch('admin/loyalty-points', '' ,'')
            .then(response => {
                this.loyalty = response.data.data
                this.loadingRequest = false;
            })
            .catch(err => {
                if(Request.statusIsFaield(err)){
                    this.$router.push('/login')
                    localStorage.removeItem('token')
                }
            });
        },
    },
}
</script>
<style lang="scss" scoped>
.saveBtn{
    margin-top: 0;
    display: block;
    &:hover{
        color: #FFF;
    }
}
</style>