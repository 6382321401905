<template>
    <div>
        <HeaderBg title="إضافة سوْال" />

        <div class="form_container">
            <form @submit.prevent="addQuestion">
                <div class="input_container">
                    <label> العنوان باللغة العربية</label>
                    <input type="text" v-model="question.translation[0].name">
                </div>
                <div class="input_container">
                    <label> العنوان باللغة الإنجليزية</label>
                    <input type="text" v-model="question.translation[1].name">
                </div>
                <div class="input_container">
                    <label>المحتوي باللغة العربية</label>
                    <input type="text" v-model="question.translation[0].content">
                </div>
                <div class="input_container">
                    <label>المحتوي باللغة الإنجليزية</label>
                    <input type="text" v-model="question.translation[1].content">
                </div>

                <div class="add_new_question saveBtn" @click="addNewQuestion">
                    add question
                </div>
                <div class="question_container" v-for="question in question.questions" :key="question">
                    <div class="input_container">
                        <label>السوْال باللغة العربية</label>
                        <input type="text" v-model="question[0].question">
                    </div>
                    <div class="input_container">
                        <label>الإجابة باللغة العربية</label>
                        <input type="text" v-model="question[0].answer">
                    </div>
                    <div class="input_container">
                        <label>السوْال باللغة الإنجليزية</label>
                        <input type="text" v-model="question[1].question">
                    </div>
                    <div class="input_container">
                        <label>الإجابة باللغة الإنجليزية</label>
                        <input type="text" v-model="question[1].answer">
                    </div>
                </div>

                <div class="alert alert-danger mt-2" role="alert" v-if="ErrorCheck == true">
                    <p v-for="(error, index) in errors" :key="index"> {{error}} </p>
                </div>
                <button class="saveBtn" v-if="postLoaded == false">
                    {{this.$route.params.id !== undefined ? ' تعديل' : 'حفظ +' }}
                </button>
                <button class="saveBtn" v-if="postLoaded == true"><b-spinner></b-spinner></button>
            </form>
        </div>
    </div>
</template>
<script>
import axios from 'axios';
import HeaderBg from '../../global/HeaderBg/HeaderBg';
import Request from '../../../services/Request';

export default {
    name :'AddQuestions',
    components: {HeaderBg},
    data(){
        return{
            postLoaded: false,
            question: {
                translation : [
                    {
                        name : "",
                        content : "",
                        local : "ar"
                    },
                    {
                        name : "",
                        content : "",
                        local : "en"
                    }
                ],
                questions : [
                    [
                        {
                            question : "",
                            answer : "",
                            local : "ar"
                        },
                        {
                            question : "",
                            answer : "",
                            local : "en"
                        }
                    ]

                ]
            },
            errors: [],
            ErrorCheck: false,
        }
    },
    mounted(){
        this.getQuestion();
    },
    methods:{
        addNewQuestion(){
            this.question.questions.push(
                [
                    {
                        question : "",
                        answer : "",
                        local : "ar"
                    },
                    {
                        question : "",
                        answer : "",
                        local : "en"
                    }
                ]
            )
        },
        uploadNewsImg(e) {
            this.news.image = e.target.files[0];
            this.imgUrl = URL.createObjectURL(e.target.files[0]);
        },
        addQuestion(){
            this.postLoaded = true;

            if(this.$route.params.id !== undefined){
                axios.put(`https://app.almujtama.com.sa/admin/aboutCompany/${this.$route.params.id}`, this.question, {
                    headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
                })
                .then( () => {
                    this.$router.push('/admin/questions')
                    this.postLoaded = false
                })  
                .catch(err =>  {
                    this.errors = err.response.data.errors;
                    this.ErrorCheck = true;
                    this.postLoaded = false;
                    
                })
            } else {
                axios.post('https://app.almujtama.com.sa/admin/aboutCompany', this.question, {
                    headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
                })
                .then( () => {
                    this.$router.push('/admin/questions')
                    this.postLoaded = false
                })  
                .catch(err =>  {
                    this.errors = err.response.data.errors;
                    this.ErrorCheck = true;
                    this.postLoaded = false;
                })
            }
        },
        getQuestion(){
            if(this.$route.params.id !== undefined){
                Request.fetch(`admin/aboutCompany/${this.$route.params.id}`)
                .then(response => {
                    console.log(response)
                    this.question.translation[0].name = response.data.data.translation[0].name
                    this.question.translation[0].content = response.data.data.translation[0].content
                    this.question.translation[1].name = response.data.data.translation[1].name
                    this.question.translation[1].content = response.data.data.translation[1].content
                })
                .catch(err => {
                    if(Request.statusIsFaield(err)){
                        this.$router.push('/login')
                        localStorage.removeItem('token')
                    }
                });
            }
        }
    }
}
</script>
<style lang="scss" scoped>
.question_container{
    border: 1px solid #DDD;
    padding: 20px;
}
.form_container{
    background-color: #FFF;
    border-radius: 10px;
    margin: 40px auto;
    padding: 70px 100px;
    @media (max-width: 576px) {
        width: 97%;
        padding: 30px;
    }
    form{
        > div{
            margin-bottom: 40px;
            label{
                display: block;
                text-align: center;
                font-size: 16px;
                color: #717171;
                font-weight: 600;
                margin-bottom: 10px;
            }
            input{
                border: 1px solid #71717191;
                font-size: 16px;
                padding: 5px 10px;
                border-radius: 10px;
                display: block;
                margin: auto;
                width: 50%;
            }
        }
        button{
            margin: 30px auto 0 auto;   
        }
    }
}
.upload_img{
            display: flex;
            align-items: center;
            margin-bottom: 40px;
            width: 100%;
            span{
                font-size: 16px;
                color: #656565;
                margin-left: 50px;
            }
            .img_container{
                width: 80px;
                height: 80px;
                border-radius: 50%;
                background-color: #FAFAFA;
                display: flex;
                justify-content: center;
                align-items: center;
                position: relative;
                img{
                    width: 100%;
                }
                .photo_upload{
                    
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    position: absolute;
                    bottom: 0;
                    right: 0;
                    img{
                        width: 70%;
                    }
                }
            }
        }
</style>