<template>
    <div>
        <HeaderBg :img="img" title="إفصاحات سوق المال" />
        <Alert 
            v-if="alertToggle == true"
            :acceptedDelete="acceptedDeleteFinance" 
            messege="هل أنت متأكد من مسح الخبر ؟"
        />
        <header class="admin_content_header">
            <div class="filter">
                <!-- <select>
                    <option value="" selected disabled>المدينة</option>
                </select> -->
            </div>
            <div class="search">
                <!-- <input type="text" placeholder="البحث برقم الفرع"> -->
            </div>
            <router-link to="/admin/finance-market/add-finance-market"> + إضافة خبر جديد </router-link>
            
        </header>
        <RequestSpinner v-if="loadingRequest == true" />
        <div class="main_table" v-if="loadingRequest == false">
            <table width="100%">
                <thead>
                    <tr>
                        <th>الإسم باللغه العربية</th>
                        <th>الإسم باللغه الإنجليزية</th>
                        <th>الرابط</th>
                        <th></th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="financeMarket in financeMarket" :key="financeMarket.id">
                        <td> {{financeMarket.translation[0].name}} </td>
                        <td> {{financeMarket.translation[1].name}} </td>
                        <td>
                            <a target="_blank" :href="financeMarket.url"> {{financeMarket.url}} </a></td>
                        <td>
                            <div class="block actions">
                                <div class="delete" @click="() => deleteData(financeMarket.id)">
                                    <b-icon icon="trash" aria-hidden="true"></b-icon>
                                </div>
                                <div class="edit" @click="() => editFinance(financeMarket.id)">
                                    <img src="../../../assets/images/editLogo.svg" alt="">
                                </div>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="pagination mt-5" v-if="financeMarket.length >= 1">
            <b-pagination-nav align="center" :link-gen="linkGen" :number-of-pages="pages" use-router v-model="currentPage"></b-pagination-nav>
        </div>
    </div>
</template>
<script>
import HeaderBg from '../../global/HeaderBg/HeaderBg';
import RequestSpinner from '../../global/loadingSpinners/RequestSpinner';
import store from "@/store/index.js";
import Request from '../../../services/Request';
import Alert from '../../global/Alert/Alert';

export default {
    name: 'FinanceMarket',
    components: {HeaderBg, RequestSpinner, Alert},
    data(){
        return{
            loadingRequest: true,
            img: require('../../../assets/images/saleWhite.svg'),
            financeMarket: [],

            deleteID: '',
            alertToggle: false,

             // pagination
            apiParams: '',
            currentPage: 1,
            pages: 1,
        }
    },
    mounted(){
        this.getFinance();
    },
    methods:{
        getFinance(){
            Request.fetch('admin/financeMarket', this.apiParams , {
                limit: store.state.limit
            })
            .then((response) => {
                this.financeMarket = response.data.data
                this.loadingRequest = false;
                this.pages = Math.ceil(response.data.total / store.state.limit) 
            })
            .catch(err => {
                if(Request.statusIsFaield(err)){
                    this.$router.push('/login')
                    localStorage.removeItem('token')
                }
            });
        },
        editFinance(id){
            this.$router.push(`/admin/finance-market/add-finance-market/${id}`)
        },
        deleteData(id){
            this.deleteID = id;
            this.alertToggle = true;
        },
        acceptedDeleteFinance(){
            Request.delete('admin/financeMarket',this.deleteID)
            .then( () => {
                this.getFinance();
            })
        },

        // pagination function
        linkGen(pageNum) {
            return pageNum === 1 ? '?' : `?page=${pageNum}`   
        },
    },
    watch: {
        // pagination
        currentPage: function(val) {
            if(val === 1){
                this.apiParams = '';
                this.getFinance();
            } else{
                this.apiParams = `?page=${val}`;
                this.getFinance();
            }
        }
    }
}
</script>
<style lang="scss" scoped>
.saveBtn{
    margin-top: 0;
    display: block;
    &:hover{
        color: #FFF;
    }
}
.actions{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    .delete,
    .edit{
        width: 40px;
        height: 34px;
        border-radius: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #FF002B;
        cursor: pointer;
        svg{
            color: #FFF;
            font-size: 25px;
        }
    }
    .edit{
        background-color: #78A28F;
        margin-right: 10px;
    }
}
</style>