<template>
    <div>
        <HeaderBg :img="img" title="إضافة نقاط ولاء" />

        <div class="form_container">
                    <div class="first_section">
                        <div class="input_container">
                            <label>الريال الى نقاط ولاء :</label>
                            <input type="number" v-model="loyalty.sar_to_loyalty_points">
                        </div>
                        <div class="input_container">
                            <label>اقل نقاط ولاء</label>
                            <input type="number" v-model="loyalty.minimum_loyalty_points">
                        </div>
                        <div class="input_container">
                            <label>أكثر نقاط ولاء</label>
                            <input type="number" v-model="loyalty.maximum_loyalty_points">
                        </div>
                    </div>    
                 
                    <div class="alert alert-danger" role="alert" v-if="ErrorCheck == true">
                        <p v-for="(error, index) in errors" :key="index"> {{error}} </p>
                    </div>
                    <button @click="addLoyaltyPoints" class="saveBtn" v-if="postLoaded == false">
                        {{this.$route.params.id !== undefined ? ' تعديل' : 'حفظ +' }}
                    </button>
                    <button class="saveBtn" v-if="postLoaded == true"><b-spinner></b-spinner></button>
            </div>
    </div>
</template>
<script>
import axios from 'axios';
import HeaderBg from '../../global/HeaderBg/HeaderBg';
import store from "@/store/index.js";
export default {
    name :'Loyalty',
    components: {HeaderBg},
    data(){
        return{
            img: require('../../../assets/images/prec.png'),
            loadingRequest: false,
            postLoaded: false,
            errors: [],
            ErrorCheck: false,
            loyalty:{
                sar_to_loyalty_points: '',
                maximum_loyalty_points: '',
                minimum_loyalty_points: '',
            }
        }
    },
    mounted(){

    },
    methods:{

        addLoyaltyPoints(){
            this.postLoaded = true;
            if(this.$route.params.id !== undefined){
                axios.post(`${store.state.domain}/admin/loyalty-points/${this.$route.params.id}`, this.loyalty, {
                    headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
                })
                .then( () => {
                    this.$router.push('/store-admin/loyalty')
                    this.postLoaded = false
                })  
                .catch(err =>  {
                    this.errors = err.response.data.errors;
                    this.ErrorCheck = true;
                    this.postLoaded = false;
                    
                })
            } else {
                axios.post(`${store.state.domain}/admin/loyalty-points`, this.loyalty, {
                    headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
                })
                .then( () => {
                    this.$router.push('/store-admin/loyalty')
                    this.postLoaded = false
                })  
                .catch(err =>  {
                    this.errors = err.response.data.errors;
                    this.ErrorCheck = true;
                    this.postLoaded = false;
                    
                })
            }
        },

    }
}
</script>
<style lang="scss" scoped>

.form_container{
        width: 100%;
            .first_section{
                display: flex;
                justify-content: space-between;
                background-color: #FFF;
                flex-wrap: wrap;
                border-radius: 10px;
                padding: 30px 20px;
                width: 100%;
                margin-bottom: 26px;
                box-shadow: 0px 3px 6px #00000029;
            }
           
        .input_container{
            margin-bottom: 16px;
            width: 23%;
            @media (max-width: 1440px) {
                width: 45%;
            }
            @media (max-width: 991px) {
                width: 100%;
            }
            label{
                font-size: 14px;
                white-space: nowrap;
                font-weight: 600;
                display: block;
                margin-bottom: 10px;
                @media (max-width: 768px) {
                    width: 100%;
                }
            }
            input{
                background-color: #FFF;
                border-radius: 10px;
                padding: 10px;
                font-size: 14px;
                border: 1px solid #C5C5C5;
                width: 100%;
                &::placeholder{
                    font-size: 14px;
                    color: #C5C5C5;
                }
            }
        }
    }

    .products_container{
            border: 1px solid #cecece;
            border-radius: 10px;
            padding: 10px;
            margin-bottom: 40px;
            height: 200px;
            overflow-y: scroll;
            width: 100%;
            .single_product{
                display: flex;
                align-items: center;
                margin-bottom: 10px;
                padding: 5px 10px;
                .img_container{
                    width: 50px;
                    height: 50px;
                    border-radius: 50%;
                    overflow: hidden;
                    margin-left: 20px;
                    img{
                        width: 100%;
                    }
                }
                &:hover{
                    background-color: #78A28F;
                }
                cursor: pointer;
                span{
                    font-size: 12px;
                    font-weight: 900;
                    color: #1E1E1E;
                    margin-left: 20px;
                }
                .deleteProduct{
                    border-radius: 2px 4px;
                    cursor: pointer;
                    padding: 8px;
                    background-color: red;

                    svg{
                        font-size: 20px;
                        color: #FFF;
                    }
                }
            }
        }
        .chosen_products .single_product{
            cursor: default !important;
            &:hover{
                background-color: transparent !important;
            }
        }
</style>