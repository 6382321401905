<template>
<!-- Admin.vue -->
    <div class="admin_page">
        <div class="side_menu" :class="showSideMenu == true ? 'menuShow' : 'menuHide'">
            <div class="toggleMenu" @click="toggleMenu">
                <b-icon icon="caret-right-fill" aria-hidden="true"></b-icon>
            </div>
            <div class="logo">
                <img src="../../assets/images/newLogo.png" alt="">
            </div>
            <router-link to="/store-admin/statistics" class="store_manage" v-if="store_management == true" >إدارة المتجر</router-link>

            <div class="links bigScreen" @click="linkUrlCheck">
                <ul>
                    <li>
                        <router-link to="/admin/main" :class="hoverType == 'main' ? 'aciveLink' : ''"> 
                            <div class="img_container"><img src="../../assets/images/minpage.png" alt=""></div> 
                            الرئيسية 
                        </router-link>
                    </li>
                    <li v-if="branches_list_permission == true">
                        <router-link to="/admin/branches" :class="hoverType == 'branches' ? 'aciveLink' : ''">
                            <div class="img_container"><img src="../../assets/images/pharmacyLink.png" alt=""></div> 
                             الصيدليات 
                        </router-link>
                    </li>
                    <li @click="changeArrow" v-if="list_articles_sections_permission == true || list_articles_permission == true">
                        <router-link  to="" :class="hoverType == 'SupportTickets' ? 'aciveLink' : ''" v-b-toggle="'collapse-1'" variant="primary">
                            <div class="img_container"><img src="../../assets/images/magazin.png" alt=""></div> 
                            المجلة الإلكترونية 
                            <div class="mx-2">
                                <b-icon v-if="journalArrow == true" icon="chevron-down" aria-hidden="true"></b-icon>
                                <b-icon v-if="journalArrow == false" icon="chevron-up" aria-hidden="true"></b-icon>
                            </div>
                        </router-link>
                        <b-collapse :id="'collapse-1'" class="w-100">
                        <ul>
                            <li v-if="list_articles_sections_permission == true">
                                <router-link to="/admin/articles-sections" :class="hoverType == 'articles-sections' ? 'aciveLink' : ''">
                                    أقسام المقالات 
                                </router-link>
                            </li>
                            <li v-if="list_articles_permission == true">
                                <router-link to="/admin/articles" :class="hoverType == 'articles' ? 'aciveLink' : ''">
                                    المقالات
                                </router-link>
                            </li>
                        </ul>
                        </b-collapse>
                    </li>
                    <li v-if="employment_request_permission == true">
                        <router-link to="/admin/employment-applications" :class="hoverType == 'employment-applications' ? 'aciveLink' : ''">
                            <div class="img_container"><img src="../../assets/images/jops.png" alt=""></div> 
                            طلبات التوظيف
                        </router-link>
                    </li>
                    <li @click="changeInvistoresArrow" v-if="key_performance_index_permission == true || key_index_permission == true || report_results_permission == true">
                        <router-link to="" :class="hoverType == 'SupportTickets' ? 'aciveLink' : ''" v-b-toggle="'collapse-2'" variant="primary">
                            <div class="img_container"><img src="../../assets/images/invistmentLogo.png" alt=""></div> 
                            علاقات المستثمرين
                            <div class="mx-2">
                                <b-icon v-if="InvistoresArrow == true" icon="chevron-down" aria-hidden="true"></b-icon>
                                <b-icon v-if="InvistoresArrow == false" icon="chevron-up" aria-hidden="true"></b-icon>
                            </div>
                        </router-link>
                    </li>
                    <b-collapse :id="'collapse-2'" class="w-100">
                        <li v-if="key_performance_index_permission == true">
                            <router-link to="/admin/key-metrics" :class="hoverType == 'key-metrics' ? 'aciveLink' : ''">
                                <div class="img_container"></div> 
                                مؤشرات الأداء الرئيسية
                            </router-link>
                        </li>
                        <li v-if="key_index_permission == true"> 
                            <router-link to="/admin/indicator-performance" :class="hoverType == 'indicator-performance' ? 'aciveLink' : ''">
                                <div class="img_container"></div> 
                                المؤشرات الرئيسية
                            </router-link>
                        </li>
                        <li v-if="report_results_permission == true">
                            <router-link to="/admin/results-and-reports" :class="hoverType == 'results-and-reports' ? 'aciveLink' : ''">
                                <div class="img_container"></div> 
                                النتائج و التقارير
                            </router-link>
                        </li>
                        <li>
                            <router-link to="/admin/finance-market" :class="hoverType == 'finance-market' ? 'aciveLink' : ''">
                                <div class="img_container">
                                    <!-- <img src="../../assets/images/sale.svg" alt=""> -->
                                    </div> 
                                إفصاحات سوق المال
                            </router-link>
                        </li>
                        <li>
                            <router-link to="/admin/investigator" :class="hoverType == 'investigator' ? 'aciveLink' : ''">
                                <div class="img_container">
                                    <!-- <img src="../../assets/images/sale.svg" alt=""> -->
                                    </div> 
                                عن الشركة
                            </router-link>
                        </li>
                        <li>
                            <router-link to="/admin/questions" :class="hoverType == 'questions' ? 'aciveLink' : ''">
                                <div class="img_container">
                                    <!-- <img src="../../assets/images/sale.svg" alt=""> -->
                                    </div> 
                                الأسئلة
                            </router-link>
                        </li>
                    </b-collapse>
                    <li v-if="list_users_permission == true">
                        <router-link to="/admin/admin-users" :class="hoverType == 'admin-users' ? 'aciveLink' : ''">
                            <div class="img_container"><img src="../../assets/images/setting.png" alt=""></div> 
                            المستخدمين
                        </router-link>
                    </li>
                    <li>
                        <router-link to="/admin/news" :class="hoverType == 'news' ? 'aciveLink' : ''">
                            <div class="img_container">
                                <img src="../../assets/images/sale.svg" alt="">
                                </div> 
                            الأخبار
                        </router-link>
                    </li>
                    <li>
                        <router-link to="/login" >
                            <div class="img_container"></div>
                            تسجيل الخروج
                        </router-link>
                    </li>
                 
                </ul>
                
            </div>
            <div class="links smallScreen" @click="linkUrlCheck">
                <ul>
                    <li @click="toggleMenu">
                        <router-link to="/admin/main" :class="hoverType == 'main' ? 'aciveLink' : ''"> 
                            <div class="img_container"><img src="../../assets/images/minpage.png" alt=""></div> 
                            الرئيسية 
                        </router-link>
                    </li>
                    <li v-if="branches_list_permission == true" @click="toggleMenu">
                        <router-link to="/admin/branches" :class="hoverType == 'branches' ? 'aciveLink' : ''">
                            <div class="img_container"><img src="../../assets/images/pharmacyLink.png" alt=""></div> 
                             الصيدليات 
                        </router-link>
                    </li>
                    <li @click="changeArrow" v-if="list_articles_sections_permission == true || list_articles_permission == true" >
                        <router-link  to="" :class="hoverType == 'SupportTickets' ? 'aciveLink' : ''" v-b-toggle="'collapse-1'" variant="primary">
                            <div class="img_container"><img src="../../assets/images/magazin.png" alt=""></div> 
                            المجلة الإلكترونية 
                            <div class="mx-2">
                                <b-icon v-if="journalArrow == true" icon="chevron-down" aria-hidden="true"></b-icon>
                                <b-icon v-if="journalArrow == false" icon="chevron-up" aria-hidden="true"></b-icon>
                            </div>
                        </router-link>
                        <b-collapse :id="'collapse-1'" class="w-100">
                        <ul>
                            <li v-if="list_articles_sections_permission == true" @click="toggleMenu">
                                <router-link to="/admin/articles-sections" :class="hoverType == 'articles-sections' ? 'aciveLink' : ''">
                                    أقسام المقالات 
                                </router-link>
                            </li>
                            <li v-if="list_articles_permission == true" @click="toggleMenu">
                                <router-link to="/admin/articles" :class="hoverType == 'articles' ? 'aciveLink' : ''">
                                    المقالات
                                </router-link>
                            </li>
                        </ul>
                        </b-collapse>
                    </li>
                    <li v-if="employment_request_permission == true" @click="toggleMenu">
                        <router-link to="/admin/employment-applications" :class="hoverType == 'employment-applications' ? 'aciveLink' : ''">
                            <div class="img_container"><img src="../../assets/images/jops.png" alt=""></div> 
                            طلبات التوظيف
                        </router-link>
                    </li>
                    <li @click="changeInvistoresArrow" v-if="key_performance_index_permission == true || key_index_permission == true || report_results_permission == true">
                        <router-link to="" :class="hoverType == 'SupportTickets' ? 'aciveLink' : ''" v-b-toggle="'collapse-2'" variant="primary">
                            <div class="img_container"><img src="../../assets/images/invistmentLogo.png" alt=""></div> 
                            علاقات المستثمرين
                            <div class="mx-2">
                                <b-icon v-if="InvistoresArrow == true" icon="chevron-down" aria-hidden="true"></b-icon>
                                <b-icon v-if="InvistoresArrow == false" icon="chevron-up" aria-hidden="true"></b-icon>
                            </div>
                        </router-link>
                    </li>
                    <b-collapse :id="'collapse-2'" class="w-100">
                        <li v-if="key_performance_index_permission == true" @click="toggleMenu">
                            <router-link to="/admin/key-metrics" :class="hoverType == 'key-metrics' ? 'aciveLink' : ''">
                                <div class="img_container"></div> 
                                مؤشرات الأداء الرئيسية
                            </router-link>
                        </li>
                        <li v-if="key_index_permission == true" @click="toggleMenu"> 
                            <router-link to="/admin/indicator-performance" :class="hoverType == 'indicator-performance' ? 'aciveLink' : ''">
                                <div class="img_container"></div> 
                                المؤشرات الرئيسية
                            </router-link>
                        </li>
                        <li v-if="report_results_permission == true" @click="toggleMenu">
                            <router-link to="/admin/results-and-reports" :class="hoverType == 'results-and-reports' ? 'aciveLink' : ''">
                                <div class="img_container"></div> 
                                النتائج و التقارير
                            </router-link>
                        </li>
                        <li @click="toggleMenu">
                            <router-link to="/admin/finance-market" :class="hoverType == 'finance-market' ? 'aciveLink' : ''">
                                <div class="img_container">
                                    <!-- <img src="../../assets/images/sale.svg" alt=""> -->
                                    </div> 
                                إفصاحات سوق المال
                            </router-link>
                        </li>
                        <li @click="toggleMenu">
                            <router-link to="/admin/investigator" :class="hoverType == 'investigator' ? 'aciveLink' : ''">
                                <div class="img_container">
                                    <!-- <img src="../../assets/images/sale.svg" alt=""> -->
                                    </div> 
                                عن الشركة
                            </router-link>
                        </li>
                        <li @click="toggleMenu">
                            <router-link to="/admin/questions" :class="hoverType == 'questions' ? 'aciveLink' : ''">
                                <div class="img_container">
                                    <!-- <img src="../../assets/images/sale.svg" alt=""> -->
                                    </div> 
                                الأسئلة
                            </router-link>
                        </li>
                    </b-collapse>
                    <li v-if="list_users_permission == true" @click="toggleMenu">
                        <router-link to="/admin/admin-users" :class="hoverType == 'admin-users' ? 'aciveLink' : ''">
                            <div class="img_container"><img src="../../assets/images/setting.png" alt=""></div> 
                            المستخدمين
                        </router-link>
                    </li>
                    <li @click="toggleMenu">
                        <router-link to="/admin/news" :class="hoverType == 'news' ? 'aciveLink' : ''">
                            <div class="img_container">
                                <img src="../../assets/images/sale.svg" alt="">
                                </div> 
                            الأخبار
                        </router-link>
                    </li>
                    <li>
                        <router-link to="/login" >
                            <div class="img_container"></div>
                            تسجيل الخروج
                        </router-link>
                    </li>
                 
                </ul>
                
            </div>

        </div>
        <div class="content" :class="showSideMenu == true ? 'contentShow' : 'contenthide'">
            <div class="navBar">
                <div class="side">
                    <div class="menu_icon" @click="toggleMenu">
                        <img src="../../assets/images/menuIcon.png" alt="">
                    </div>
                </div>
                <div class="side">
                    <div class="notification">
                        <img src="../../assets/images/notificationIcon.png" alt="">
                    </div>
                    <div class="name_container">
                        <h3>{{profile.name}}</h3>
                        <h4>{{profile.role[0]}}</h4>
                    </div>
                    <div class="img_container">
                        <img :src="profile.image" alt="">
                        <div class="active_status"></div>
                    </div>
                    
                </div>
            </div>

            <div class="main_content">
                <router-view></router-view>
            </div>
        </div>
        
    </div>
</template>
<script>
import axios from 'axios';
export default {
    name: 'Admin',
    data(){
        return{
            showSideMenu: true,
            InvistoresArrow: true,
            journalArrow: true,
            hoverType: '',
            profile: {},

            // permissions
            branches_list_permission: false,
            employment_request_permission: false,
            list_articles_sections_permission: false,
            list_articles_permission: false,
            list_users_permission: false,
            key_performance_index_permission: false,
            key_index_permission: false,
            report_results_permission: false,
            store_management: false,
        }
    },
    beforeMount(){
        var permissions = JSON.parse(localStorage.getItem("permissions"));

        if(permissions.includes('list branches')){
            this.branches_list_permission = true
        } 
        if(permissions.includes('list articles sections')){
            this.list_articles_sections_permission = true
        } 
        if(permissions.includes('list articles')){
            this.list_articles_permission = true
        } 
        if(permissions.includes('list employment request')){
            this.employment_request_permission = true
        } 
        if(permissions.includes('list users')){
            this.list_users_permission = true
        } 

        if(permissions.includes('list key performance indicators')){
            this.key_performance_index_permission = true
        } 

        if(permissions.includes('list key indicators')){
            this.key_index_permission = true
        } 

        if(permissions.includes('list reports and results')){
            this.report_results_permission = true
        } 

        if(permissions.includes('store management')){
            this.store_management = true
        } 
    },
    mounted(){
        this.linkUrlCheck();
        this.getProfile();
    },
    methods: {
        changeArrow(){
            this.journalArrow = !this.journalArrow
        },
        changeInvistoresArrow(){
            this.InvistoresArrow = !this.InvistoresArrow
        },
        linkUrlCheck(){
            if (window.location.href.indexOf("main") > -1) {
                this.hoverType = 'main'
            } else if(window.location.href.indexOf("branches") > -1){
                this.hoverType = 'branches'
            } else if(window.location.href.indexOf("articles-sections") > -1){
                this.hoverType = 'articles-sections'
            } else if(window.location.href.indexOf("articles") > -1){
                this.hoverType = 'articles'
            } else if(window.location.href.indexOf("employment-applications") > -1){
                this.hoverType = 'employment-applications'
            } else if(window.location.href.indexOf("key-performance-indicators") > -1){
                this.hoverType = 'key-performance-indicators'
            } else if(window.location.href.indexOf("results-and-reports") > -1){
                this.hoverType = 'results-and-reports'
            } else if(window.location.href.indexOf("indicator-performance") > -1){
                this.hoverType = 'indicator-performance'
            } else if(window.location.href.indexOf("key-metrics") > -1){
                this.hoverType = 'key-metrics'
            } else if(window.location.href.indexOf("admin-users") > -1){
                this.hoverType = 'admin-users'
            } else if(window.location.href.indexOf("news") > -1){
                this.hoverType = 'news'
            } else if(window.location.href.indexOf("finance-market") > -1){
                this.hoverType = 'finance-market'
            } else if(window.location.href.indexOf("investigator") > -1){
                this.hoverType = 'investigator'
            } else if(window.location.href.indexOf("questions") > -1){
                this.hoverType = 'questions'
            }
        },
        toggleMenu(){
            this.showSideMenu = !this.showSideMenu
        },
        getProfile(){
            axios.get(`https://app.almujtama.com.sa/admin/profile`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    'Access-Control-Allow-Headers': '*',
                    'Authorization': 'Bearer '+ localStorage.getItem('token'),
                },
            })
            .then((response) => {
                this.profile = response.data.data
            
            })
        },
    }
}
</script>
<style lang="scss" scoped>
.menuShow{
    width: 314px;
    @media (max-width: 991px) {
        width: 0;
    }
}
.menuHide{
    width: 0;
    @media (max-width: 991px) {
        width: 225px;
        right: 0 !important;
    }
}
.contentShow{
    width: calc(100% - 314px);
    @media (max-width: 991px) {
        width: 100%;
    }
}
.contenthide{
    width: 100%;
    @media (max-width: 991px) {
        width: 100%;
    }
}
.admin_page{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    @media (max-width: 991px) {
        position: relative;
    }
    .side_menu{
        transition: .3s ease-in-out;
        background-color: #FFF;
        min-height: 100vh;
        @media (max-width: 991px) {
            position: absolute;
            top: 0;
            right: -277px;
            bottom: 0;
            background-color: #FFFF;
            z-index: 99;
            width: 225px;
        }
        @media (max-width: 576px) {
            right: -110%;
            width: 100%;
        }
        .toggleMenu{
            display: flex;
            justify-content: flex-end;
            padding: 20px 0 0 0;
            display: none;
            @media (max-width: 991px) {
                display: flex;
            }
            svg{
                font-size: 35px;
                color: #78A28F; 
            }
        }
        .logo{
            width: 186px;
            margin: 10px auto 40px auto;
            img{
                width: 100%;
            }
        }
        .store_manage{
            width: 164px;
            background-color: #78A28F;
            padding: 10px;
            text-align: center;
            font-size: 16px;
            color: #FFF;
            display: block;
            margin: auto auto 20px auto;
        }

        .links{
            ul{
                li{
                    a{
                        font-size: 16px;
                        color: #444444;
                        display: flex;
                        align-items: center;
                        padding: 15px 20px;
                        -webkit-box-shadow: -1px 6px 4px 0px #EFEFEF;
                        -moz-box-shadow: -1px 6px 4px 0px #EFEFEF;
                        box-shadow: -1px 6px 4px 0px #EFEFEF;
                        .img_container{
                            width: 30px;
                            margin-left: 10px;
                            img{
                                width: 25px;
                            }
                        }
                        
                    }
                    .aciveLink{
                        background-color: #D8D253;  
                        color: #FFF;  
                    }
                    >ul{
                        li{
                            a{
                                padding: 15px 57px 15px 20px;
                            }
                        }
                    }
                }
            }
            
        }
        .bigScreen{
            @media (max-width: 991px) {
                display: none;
            }
        }
        .smallScreen{
            display: none;
            @media (max-width: 991px) {
                display: block;
            }
        }
    }
    .content{
        transition: .3s ease-in-out;
        padding: 30px;
        background-color: #FAFAFA;
        min-height: 100vh;
        @media (max-width: 576px) {
            padding: 10px;
        }
        .navBar{
            border-radius: 15px;
            background-color: #FFF;
            padding: 10px 20px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            align-items: center;
            margin-bottom: 40px;
            .side{
                display: flex;
                align-items: center;
                .menu_icon{
                    cursor: pointer;
                }
                .img_container{
                    width: 61px;
                    height: 61px;
                    border-radius: 50%;
                    background-color: #EFEFEF;
                    position: relative;
                    img{
                        width: 100%;
                        height: 100%;
                        border-radius: 50%;
                    }
                    .active_status{
                        width: 16px;
                        height: 16px;
                        background-color: #28C66F;
                        border-radius: 50%;
                        position: absolute;
                        bottom: 0;
                        left: 0;
                    }
                }
                .name_container{
                    margin-left: 23px;
                    h3{
                        font-size: 16px;
                        font-weight: 600;
                        color: #1E1E1E;
                        margin-bottom: 2px;
                        font-family: flatMedium;
                    }
                    h4{
                        font-size: 14px;
                        color: #656565;
                        margin-bottom: 0;
                    }
                }
                .notification{
                    margin-left: 23px;
                }
            }
        }
    }
}
</style>