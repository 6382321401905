<template>
    <div>
        <HeaderBg :img="img" title="إضافة خبر" />

        <div class="form_container">
            <form @submit.prevent="addFinance">
                <div class="input_container">
                    <label> اسم الخبر باللغة العربية</label>
                    <input type="text" v-model="fincance.translation[0].name">
                </div>
                <div class="input_container">
                    <label> اسم الخبر باللغة الإنجليزية</label>
                    <input type="text" v-model="fincance.translation[1].name">
                </div>
                <div class="input_container">
                    <label> الرابط </label>
                    <input type="text" v-model="fincance.url">
                </div>
                
                <div class="alert alert-danger mt-2" role="alert" v-if="ErrorCheck == true">
                    <p v-for="(error, index) in errors" :key="index"> {{error}} </p>
                </div>
                <button class="saveBtn" v-if="postLoaded == false">
                    {{this.$route.params.id !== undefined ? ' تعديل' : 'حفظ +' }}
                </button>
                <button class="saveBtn" v-if="postLoaded == true"><b-spinner></b-spinner></button>
            </form>
        </div>
    </div>
</template>
<script>
import store from "@/store/index.js";
import axios from 'axios';
import HeaderBg from '../../global/HeaderBg/HeaderBg';
import Request from '../../../services/Request';
export default {
    name :'AddFinanceMarket',
    components: {HeaderBg},
    data(){
        return{
            img: require('../../../assets/images/locationLarg.png'),
            postLoaded: false,
            fincance: {
                url : '',
                translation : [
                    {
                        name : "",
                        local : "ar"
                    },
                    {
                        name : "",
                        local : "en"
                    }
                ]
            },
            errors: [],
            ErrorCheck: false,
        }
    },
    mounted(){
        this.getFinance();
    },
    methods:{
        addFinance(){
            this.postLoaded = true;
            if(this.$route.params.id !== undefined){
                axios.put(`${store.state.domain}/admin/financeMarket/${this.$route.params.id}`, this.fincance, {
                    headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
                })
                .then( () => {
                    this.$router.push('/admin/finance-market')
                    this.postLoaded = false
                })  
                .catch(err =>  {
                    this.errors = err.response.data.errors;
                    this.ErrorCheck = true;
                    this.postLoaded = false;
                    
                })
            } else {
                axios.post(`${store.state.domain}/admin/financeMarket`, this.fincance, {
                    headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
                })
                .then( () => {
                    this.$router.push('/admin/finance-market')
                    this.postLoaded = false
                })  
                .catch(err =>  {
                    this.errors = err.response.data.errors;
                    this.ErrorCheck = true;
                    this.postLoaded = false;
                })
            }
        },
        getFinance(){
            if(this.$route.params.id !== undefined){
                Request.fetch(`admin/financeMarket/${this.$route.params.id}`)
                .then((response) => {
                    this.fincance.translation[0].name = response.data.data.translation[0].name
                    this.fincance.translation[1].name = response.data.data.translation[1].name
                    this.fincance.url = response.data.data.url
                })
                .catch(err => {
                    if(Request.statusIsFaield(err)){
                        this.$router.push('/login')
                        localStorage.removeItem('token')
                    }
                });
            }
        }
    }
}
</script>
<style lang="scss" scoped>
.form_container{
    background-color: #FFF;
    border-radius: 10px;
    margin: 40px auto;
    padding: 70px 100px;
    @media (max-width: 576px) {
        width: 97%;
        padding: 30px;
    }
    form{
        > div{
            margin-bottom: 40px;
            label{
                display: block;
                text-align: center;
                font-size: 16px;
                color: #717171;
                font-weight: 600;
                margin-bottom: 10px;
            }
            input{
                border: 1px solid #71717191;
                font-size: 16px;
                padding: 5px 10px;
                border-radius: 10px;
                display: block;
                margin: auto;
                width: 50%;
            }
        }
        button{
            margin: 30px auto 0 auto;   
        }
    }
}
.upload_img{
            display: flex;
            align-items: center;
            margin-bottom: 40px;
            width: 100%;
            span{
                font-size: 16px;
                color: #656565;
                margin-left: 50px;
            }
            .img_container{
                width: 80px;
                height: 80px;
                border-radius: 50%;
                background-color: #FAFAFA;
                display: flex;
                justify-content: center;
                align-items: center;
                position: relative;
                img{
                    width: 100%;
                }
                .photo_upload{
                    
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    position: absolute;
                    bottom: 0;
                    right: 0;
                    img{
                        width: 70%;
                    }
                }
            }
        }
</style>