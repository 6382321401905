export default {

  // remove this
  storeStatus: 'المتجر تحت الصيانة مؤقتاَ',
  weareSorry: 'عزيزي العميل، نعتذر عن إغلاق متجرنا الإلكتروني مؤقتًا لأعمال الصيانة. ',
  weareSorry2: 'نسعى جاهدين لتقديم أفضل تجربة تسوق ممكنة لكم، ونحتاج إلى إجراء بعض أعمال الصيانة المخطط لها لضمان استمرار عمل المتجر بسلاسة وفعالية.',

  language: 'عربي',
  writeHere : 'اكتب هنا',
  Muslim : 'مسلم',
  NonMuslim : 'غير دلك',
  single : 'أعزب',
  married : 'متزوج',
  Bachelors : 'بكالوريوس',
  Diploma : 'دبلوم',
  Masters : 'ماجستير',
  PHD : 'دكتوراة',
  Job1 : 'وظيفة',
  Job2 : 'وظيفتان',
  Job3 : 'ثلاث وظائف',
  Job4 : 'اربع وظائف',
  yes : 'نعم',
  no : 'لا',
  Professional : 'ممتاز',
  Advance : 'متوسط',
  Intermediate : 'ضعيف',

  slider:{
    news1: 'يسرنا أن نعلن أنه تم -بحمد الله- افتتاح فرع جديد لصيدلية المجتمع في حي القرنية- شارع تركي بن ماضي- جدة',
    news2: 'يسرنا أن نعلن أنه تم -بحمد الله- افتتاح فرع جديد لصيدلية المجتمع في حي العزيزية - شارع شبرا - الطائف',
    news3: 'يسرنا أن نعلن أنه تم -بحمد الله- افتتاح فرع جديد لصيدلية المجتمع في حي النظيم - طريق خريص - الرياض',
    news4: 'يسرنا أن نعلن أنه تم -بحمد الله- افتتاح فرع جديد لصيدلية المجتمع في حي القرينية- شارع مجاهد بن يوسف- جدة',
    news5: 'يسرنا أن نعلن أنه تم -بحمد الله- افتتاح فرع جديد لصيدلية المجتمع في حي القرينية- طريق الليث الفرعي- جدة',
    news6: 'يسرنا أن نعلن أنه تم -بحمد الله- افتتاح فرع جديد لصيدلية المجتمع في حي الزمرد- طريق الملك سعود - جدة',

    newOpening: 'يسرنا أن نعلن أنه تم -بحمد الله- افتتاح فرع جديد لصيدلية المجتمع في حي الصفا- طريق الأمير متعب بن عبد العزيز- جدة',
    newOpeningDescription: 'انضموا إلينا في الفرع الجديد واستفيدوا من خدماتنا المتميزة صيدليات_المجتمع #الريادة_في_خدمة_المجتمع',
  
    TheLeadingCommunityMedical:'تعلن شركة المجتمع الرائدة الطبية عن موافقة مجلس الإدارة في اجتماعه المنعقد بتاريخ 04/12/2023م على قبول استقالة عضو مجلس الإدارة الأستاذ/ هشام عمر باروم ( غير تنفيذي ) من منصبه وذلك بتاريخ 04/12/2023م على أن تسري الاستقالة من تاريخ تقديم الاستقالة في 04/12/2023م ويعود سبب الاستقالة إلى انتهاء ترخيص الجمعية العامة المنعقدة بتاريخ 06/10/2022م بشأن السماح له بالاشتراك في عضوية مجلس إدارة شركة انوفا السعودية للرعاية الصحية.',
    newBranch:'يسرنا أن نعلن أنه سيتم -بحمد الله- افتتاح فرع جديد لصيدلية المجتمع في حي السنابل- جدة انضموا إلينا في الفرع الجديد واستفيدوا من خدماتنا المتميزة صيدليات_المجتمع',
    newBranchTag1:'#لمجتمع_صحي_واع',
    newBranchTag2:'#الريادة_في_خدمة_المجتمع',
    TheLeadingCommunityMedicalDescription:'وقد عبر العضو عن شكره وامتنانه لمساهمي الشركة وأعضاء المجلس على الفترة التي قضاها كعضو في مجلس الإدارة، ويتوجه مجلس إدارة الشركة بجزيل الشكر والتقدير للأستاذ/ هشام عمر باروم متمنيين له دوام التوفيق والنجاح.',
    CommunityLeadingMedical:'تعلن شركة المجتمع الرائدة الطبية (“الشركة”) عن عزم الشركة تسجيل وإدراج أسهم الشركة البالغة (9,500,000) سهم عادي ادراجاً مباشراً في السوق الموازية “نمو” ، و سيتم الإعلان عن تاريخ الإدراج قريباً',
    CommunityLeadingMedicalDescription:'انضموا إلينا في الفرع الجديد واستفيدوا من خدماتنا المتميزة صيدليات_المجتمع #الريادة_في_خدمة_المجتمع',

    CollegeOfPharmacy :'كلية الصيدلية بجامعة الملك عبد العزيز بمدينة جدة كل الشكر للراعي الذهبي صيدلية المجتمع لمساهمتهم في نجاح فاعليتنا',
    Community_Pharmacy :'#صيدلية_المجتمع',

    TheManagementOfTheLeading  :'قامت إدارة شركة المجتمع الرائدة الطبية بتكريم الصيدلي محمد أحمد محمد علي لحصوله على المركز الرابع حسب تقييم الأداء في شهر سبتمبر.',
    TheManagementOfTheLeadingDescription  :'صيدليات المجتمع تتمنى له ولجميع شركاء النجاح المزيد من التوفيق والسداد',
  },



  landing: {
    Home: 'الرئيسية',
    AboutUs: 'تعرف علينا',
    Store: 'المتجر',
    EMagazine: 'المجلة الإلكترونية',
    Jobs: 'الوظائف',
    OurBranches: 'الفروع',
    InvestorRelations: 'علاقات المستثمرين',


    Pages: 'صفحات',
    Collectwithalmujtamajobs: 'اجمع مع المجتمع',
    ContactInformation: 'معلومات التواصل',
    PhoneNumber: 'الرقم الموحد',
    Email: 'البريد الالكتروني',
    MinistryOfHealthUnifiedNo: 'رقم وزارة الصحة الموحد',
    LicenseNo: 'رقم الترخيص',
    FoodAndDrugsAdminNo: 'رقم هيئة الدواء والغذاء',
    VigilanceProgramme: 'برنامج تيقظ ( مرتبط بهذا الرابط )',
    ContactUs: 'اتصل بنا',

  },

  // home page
  home:{
    branchesWidelySpread: 'فرعاّ منتشراّ على نطاق واسع',
    squareMeterToProvideBestQualityInSupplyAnd: 'متر مربع لتقديم أفضل جودة في الإمداد والتموين',
    ItemsInYourHands: 'صنفاً بين ايديكم',
    homeOurBranches: 'فروعنا',
    AbouttheGroupDescription: 'صيدليات المجتمع مجموعة رائدة ومتكاملة في تقديم الخدمات الصيدلانية. شركاء وزارة الصحة في تنفيذ برامج الرعاية الصحية ومبادرات منظومة الوزارة لبرامج التحول الوطني 2020 ضمن رؤية المملكة 2030.',
    AbouttheGroupDescriptionSeconde:'شركاء وزارة الصحة بالتنسيق مع الشركة الوطنية للشراء الموحد (نوبوكو) لصرف الوصفات الطبية (وصفتي) الصادرة من مراكز الرعاية الأولية والمستشفيات التابعة لوزارة الصحة إضافة إلى المستشفيات التابعة للخدمات الطبية بالقطاعات الحكومية الأخرى مثل: وزارة الدفاع ووزارة الداخلية ووزارة التعليم العالي في كل من: (جدة – مكة المكرمة – الطائف- الرياض- المدينة المنورة- الخرج- مدينة الملك عبد الله الاقتصادية- ينبع- رابغ – جازان- صبيا)',
    LeadershipInCommunityServices:'الريادة في خدمة المجتمع',
    OurServices:'خدماتنا',
    OurServicesDescription:'صيدليات المجتمع مجموعة رائدة ومتكاملة في تقديم الخدمات الصيدلانية',
    OnlineCommunity:'المجتمع أون لاين',
    OnlineCommunityEMagazine:'المجلة الإلكترونية',
    OnlineCommunityOnlineStore:'المتجر الإلكتروني',
    OnlineCommunityCustomerService:'خدمة العملاء والتواصل مع الجمهور',
    homeInvestorRelations:'علاقات المستثمرين',
    homeInvestorRelationsDescriptiom:'من خلال صفحة علاقات المستثمرين يستطيع المستثمر الاطلاع على التقارير والنتائج الخاصة بالشركة',
    RelationshipOfInvestors:'المؤشرات الرئيسية',
    CollectWithALMujtama:'اجمع مع المجتمع',
    CollectWithALMujtamaDescription:'إيمانا من مجموعة صيدليات المجتمع بأن عملاءها الكرام هم شركاء النجاح ومحور الرعاية وتقدير لعملائها الكرام، فقد طورت صيدليات المجتمع نظام الولاء حيث يتيح لعملائها الكرام الحصول بضائع مجانية تعادل 3 % من مشترياتها.',
    RegisterNow:'سجل الان',
    TheLoyaltyClientProgram:'برنامج عملاء التميز',
    TheLoyaltyClientProgramDescription:'هو برنامج يتيح لعملاء صيدليات المجتمع الحصول على بضاعة مجانية تعادل 3 % من مشترياتهم عند الوصول الى مبلغ 300 ريال   شامل ضريبة القيمة المضافة) من أي من فروعها أو الموقع الإلكتروني والتطبيق. يعد برنامج عميل التميز من أفضل برامج جمع النقاط في المملكة.',
    TheLoyaltyClientProgramDescriptionTwo:'*عدا الورقيات والحفائظ وبعض الأدوية حيث تحسب البضاعة المجانية بما يعادل 1% من قيمة المشتريات ولا يتم احتساب أي نقاط لمشتريات الحليبات ومنتجات الرضاعة الصناعية حسب تعميم وزارة الصحة بمنع الترويج للمنتجات المساعدة على الرضاعة الصناعية تشجيعاً للرضاعة الطبيعية'
  },

  // about us
  aboutUs:{
    aboutUs: 'تعرف علينا',
    AbouttheGroupDescriptionpoint1: '• صيدليات المجتمع مجموعة رائدة ومتكاملة في تقديم الخدمات الصيدلانية.',
    AbouttheGroupDescriptionpoint2: '•	شركاء وزارة الصحة في تنفيذ برامج الرعاية الصحية ومبادرات منظومة الوزارة لبرامج التحول الوطني 2020 ضمن رؤية المملكة 2030. ',
    AbouttheGroupDescriptionpoint3: '•	شركاء وزارة الصحة بالتنسيق مع الشركة الوطنية للشراء الموحد (نوبكو) لصرف الوصفات الطبية (وصفتي) الصادرة من مراكز الرعاية الأولية والمستشفيات التابعة لوزارة الصحة إضافة الى المستشفيات التابعة للخدمات الطبية بالقطاعات الحكومية الأخرى مثل: وزارة الدفاع ووزارة الداخلية ووزارة التعليم العالي في كل من: (جدة – مكة المكرمة – الطائف - الرياض - المدينة المنورة - الخرج - مدينة الملك عبد الله الاقتصادية- ينبع - رابغ – جازان- صبيا).',
    AbouttheGroupDescriptionpoint4: '•	بالتعاون مع وزارة الصحة، تقدم مجموعة صيدليات المجتمع خدمة صرف الوصفات الإلكترونية الصادرة من تطبيق (صحتي) والهاتف الموحد (937) من خلال فروعها.',
    AbouttheGroupDescriptionpoint5: '•	شركاء وزارة الصحة وعدد من المؤسسات التعليمية (جامعة الملك عبد العزيز- جامعة الطائف) لتقديم الخدمات المجتمعية وتدريب طلبة الامتياز بكلية الصيدلة والمساهمة في البحوث العلمية التي تهدف الى تطوير مهنة الصيدلة وقطاع الدواء.',
    AbouttheGroupDescriptionpoint6: '•	شركاء وزارة الصحة في تقديم خدمة التطعيمات الموسمية، إضافة الى رصد المؤشرات الحيوية ضمن الحملة الوطنية # اعرف ارقامك # تمهيداً لتطبيق نظام الملف الموحد لجميع المواطنين وذلك بالتنسيق مع الجهات الحكومية ذات العلاقة.',
    AbouttheGroupDescriptionpoint7: '•	بإشراف من وزارة الصحة تقدم صيدليات المجتمع خدمة الرعاية الصحية غير العاجلة داخل عيادات مجهزة ومخصصة لتقديم الخدمة والاستفادة من خدمة الطب الاتصالي لتقديم أفضل الخدمات الصحية.',

    OurVision:'رؤيتنا',
    OurVisionDescription:'الريادة في خدمة المجتمع لمجتمع صحي واع.',
    OurMessage:'رسالتنا',
    OurMessageDescription:'تقديم الخدمات الصيدلانية المتكاملة الى كافة شرائح المجتمع.',
    OurMessageDescription2:'حماية المجتمع من المنتج المقلد والسعر المبالغ فيه، شعارنا:(المنتج الأصل بالسعر الأقل).',
    OrganizationalChart:'الهيكل التنظيمي',
    OrganizationalChartDescription:'يُعتبَر الهيكل التنظيميّ وسيلة أو أداة لمساعدة إدارة الشركة في تحقيق أهدافها بكفاءة وفاعلية، حيث إنّ من شأنه بيان الوحدات التنظيميّة التي تتألَّف منها ضمن مستويات هرميّة مرتبطة ببعضها بروابط السُّلطة التي تتخلَّلها التعليمات والأوامر والعلاقات ويمكِّنها من تنفيذ الخطط واتخاذ القرارات وتحديد أدوار كل إدارة وموظفيها لتفادي التداخل والازدواجية.',
    OrganizationalChartDescription2:'يتواءم الهيكل التنظيمي للشركة مع متطلبات الحوكمة والشفافية ومتطلبات نظام الشركات.',
    OnlineCommunity:'المجتمع أون لاين',
    OnlineCommunityTitle:'هي خدمة رائدة تقدمها صيدليات المجتمع تهدف الى تقديم الخدمات الصيدلانية تحقيقا لمعنى طب المجتمع وذلك عن طريق الانترنت من خلال الموقع الرسمي الخاص بالمجموعة: www.almujtama.com.sa أومن خلال حسابها الرسمي لدى وسائل التواصل الاجتماعي(@almujtampharma)بكافة منصاتها الإعلامية المتاحة وبأحدث الوسائل التقنية.',
    aboutUsEMagazine:'المجلة الإلكترونية',
    aboutUsEMagazineDescription:'هي مجلة تعني بالمجال الصحي الطبي والصيدلاني وتقوم بطرح آخر المستجدات في المجال الصحي وتنشر المقالات الطبية تحت إشراف استشاري طب المجتمع ونخبة من صيادلة مجموعة صيدليات المجتمع اضافة الى عرض اهم المؤتمرات والندوات الطبية المقامة في المحافل المحلية والإقليمية والعالمية.',
    aboutUsOnlineStore:'المتجر الإلكتروني',
    aboutUsOnlineStoreDescription:'يتيح لعملائه الكرام التسوق والشراء من خلال الصيدلية الالكترونية على الموقع الرسمي كما يقدم المتجر خدمة التوصيل المجاني.',
    CustomerService:'خدمة العملاء',
    CustomerServiceDescription:'إيماناً من مجموعة صيدليات المجتمع بأن عملاءها الكرام هم شركاء النجاح ومحور الرعاية فإن اقتراحاتهم ونصائحهم وحتى شكاواهم هي محل اهتمامنا ويمكنهم تقديمها من خلال التواصل عن طريق:',
    CommunityContributions:'المشاركات المجتمعية',
    CommunityContributionsDescription:'شاركت مجموعة صيدليات المجتمع في تقديم العديد من الخدمات المجتمعية: تحقيقاً لرؤيتها ورسالتها في خدمة المجتمع ومن أهم تلك المشاركات',
    CommunityContributionsPoint1:'• المشاركة في حملة # خدمة التنبيه بمواعيد تطعيم الأطفال # بإشراف إدارة التوعية الصحية بوزارة الصحة، وقد تحصلت صيدليات المجتمع على شهادة شكر وتقدير من معالي وزير الصحة معالي الدكتور/ توفيق الربيعة تقديرا لجهودها في الحملة.',
    CommunityContributionsPoint2:'• المشاركة في حملة # عدل عاداتك # بإشراف إدارة التوعية الصحية بوزارة الصحة. ',
    CommunityContributionsPoint3:'• المشاركة في إنجاح الأسبوع الخليجي لصحة الفم والأسنان تحت شعار (الأسنان صحة وجمال) لمدة تسع سنوات.',
    CommunityContributionsPoint4:'• شركاء وزارة الصحة في تقديم خدمة التطعيم ضد الإنفلونزا الموسمية ولمدة 3 سنوات ابتداء من عام 1439هــ .',
    CommunityContributionsPoint5:'• تدريب طلبة كلية الصيدلة (مرحلة الامتياز) بناء على اتفاقية مع كل من وزارة الصحة وكلية الصيدلة بجامعة الملك عبد العزيز لمدة 6 سنوات ابتداء من عام 1439 هــ. ',
    CommunityContributionsPoint6:'• المشاركة في تقديم الخدمات التدريبية والتثقيفية والتعاونية داخل بعض المدارس.',
    CommunityContributionsPoint7:'• المشاركة في حفل تخرج طلاب و طالبات كلية الصيدلة بجامعة الملك عبد العزيز لآخر 5 سنوات، إضافة إلى رعاية جميع الفعاليات التي تقيمها كلية الصيدلة بجامعة الملك عبد العزيز.',
    CommunityContributionsPoint8:'• تشغيل وإدارة صيدليات العيادات الخارجية لعدد من مستشفيات وزارة الصحة وغيرها. ',
    CommunityContributionsPoint9:'• المساهمة الفعالة في.. برنامج "رصد" وبناء التكامل بين نظام المجموعة وهيئة الغذاء والدواء. ',
    CommunityContributionsPoint10:'• المساهمة في برنامج "تيقظ" والإبلاغ عن المجاميع العلاجية الواجب توفرها في الصيدلية المجتمعية و التي لم تكن تتوفر بها في السابق.',
    CommunityContributionsPoint11:'• اقتراح البدائل ومصادرها مما أتاح رفع نسبة التوفر وعدم الاعتماد على أسماء تجارية بعينها.',
    branchesspreadwidely:'(160 ) فرعاً منتشرا على نطاق واسع',
    branchesOnService:'(125) فرعا فى خدمتكم',
    branchesUnderEstablishment:'(35) فرعاّ تحت التأسيس ',
    OurWarehouses:'مستودعاتنا',
    OurWarehousesDecription1:'نسعى جادين لتحقيق أعلى معايير السلامة والجودة العالمية في مستودعاتنا بترخيص وإشراف الهيئة العامة للغذاء والدواء وتطبيق الأنظمة الجديدة مثل نظام التتبع الدوائي.',
    OurWarehousesDecription2:'أسطول من وسائل النقل إلى فروعنا التي تحقق المعايير المطلوبة في نقل الأدوية وباقي المستحضرات والصيدلانية والطبية والتجميلية وأغذية الأطفال وباقي أصناف الصيدلية.',
    OurWarehousesDecription3:'أكثر من (25,000) صنف بين يديكم.',
    OurWarehousesDecription4:' يقع مستودعنا المركزي في مدينة جدة في المنطقة الصناعية الثالثة "مدن" بمساحة إجمالية (21,000) متر مربع لتقديم أفضل جودة في الإمداد والتموين وتطبيق أعلى معايير الخدمات اللوجستية لفروع الشركة..',
    OurWarehousesDecription5:'( 21.000 ) متر مربع مساحة تخزينية لخدمة جميع أذواق عملائنا الكرام ',
    OurWarehousesDecription6:'يتم استلام وإرسال أكثر من( 3000 ) طرد يوميا.',
  },

  investor:{
    INVESOTRSRELATION :'علاقات المستثمرين',
    INVESOTRSRELATIONDescription :'من خلال صفحة علاقات المستثمرين يستطيع المستثمر الاطلاع على التقارير والنتائج الخاصة بالشركة',
    AboutTheGroup :'نبذة عن الشركة',
    LatestNews :'اخر الأخبار',
    ReportsAndResults :'النتائج و التقارير',
    downloadTheFile :'تنزيل الملفات',
    MoneyMarketDisclosure :'افصاحات سوق المال',
    MoneyMarketDisclosureDescription :'تعلن شركة المجتمع الرائدة الطبية عن موافقة مجلس الإدارة في اجتماعه المنعقد بتاريخ 04/12/2023م على قبول استقالة عضو مجلس الإدارة الأستاذ/ هشام عمر باروم ( غير تنفيذي ) من منصبه وذلك بتاريخ 04/12/2023م على أن تسري الاستقالة من تاريخ تقديم الاستقالة في 04/12/2023م ويعود سبب الاستقالة إلى انتهاء ترخيص الجمعية العامة المنعقدة بتاريخ 06/10/2022م بشأن السماح له بالاشتراك في عضوية مجلس إدارة شركة انوفا السعودية للرعاية الصحية.  وقد عبر العضو عن شكره وامتنانه لمساهمي الشركة وأعضاء المجلس على الفترة التي قضاها كعضو في مجلس الإدارة، ويتوجه مجلس إدارة الشركة بجزيل الشكر والتقدير للأستاذ/ هشام عمر باروم متمنيين له دوام التوفيق والنجاح.',
    AdvertisementDetails :'تفاصيل الاعلان',
    KeyIndex :'المؤشرات الرئيسية',
    KeyPerformanceIndex :'مؤشرات الأداء الرئيسية ',
    FirstQuarter :'(النصف الأول من العام 2023)',

    // questions
    quetionsHeadTitle: 'مقابلة مع الرئيس التنفيذي و العضو المنتدب لشركة المجتمع الرائدة الطبية :',
    quetionsTitle: 'تعرّف على استراتيجية شركة المجتمع الرائدة الطبية ومزاياها التنافسية',
    question1 :'1-ما تقييمكم للمنافسة في سوق الصيدليات في المملكة؟ وكم تبلغ حصتكم السوقية؟',
    answer1 :'يبلغ عدد الصيدليات المجتمعية في المملكة (9) الاف صيدلية تقريباً، وتمتلك مجموعتنا حوالي (125) صيدلية حتى تاريخه، وسيتم إضافة (40) صيدلية على الأقل مع نهاية عام 2024م بإذن الله، ومن ناحية الحصة السوقية فلا توجد إحصائية رسمية يمكن الادلاء بها في الوقت الحالي، ولكن يمكنني القول بأن شركة المجتمع الرائدة الطبية تستحوذ حالياً على ما نسبته من 8 – 10 % من عدد الوصفات التي تصدر من مختلف المستشفيات ومراكز الرعاية الصحية الأولية بالمملكة عن طريق نظام وصفتي.',
    question2 :'2-ما هي استراتيجية الشركة فيما يتعلق بالتوسع في فتح المزيد من الصيدليات؟ وما هي المناطق المستهدفة للتوسع في المستقبل؟',
    answer2 :'افتتاح المزيد من الفروع كان وما زال من أهداف الشركة مع ازدياد في وتيرته في الفترة الأخيرة، حيث يتم افتتاح بين 25 – 30 صيدلية سنويا في الوقت الحالي، ومن المتوقع خلال شهر من الآن وبعد الانتهاء من تشغيل المستودع الرئيسي على مساحة (21,000 متر مربع) أن يرتفع هذا العدد ليصبح ما تتم إضافته من فروع إلى 40 فرعا سنويا -بإذن الله-. أما بالنسبة للمناطق المستهدفة فسوف تتم مراعاة التوزيع الجغرافي والتوجه إلى مناطق غير مخدومة، فعلى الرغم من أن عدد الصيدليات المجتمعية بالمملكة (صيدلية لكل 4000 نسمة) وهو أعلى من المعدل العالمي (صيدلية لكل 8000 نسمة) إلا أن هناك مناطق شاسعة لم تصلها الخدمة. كما أن صيدليات المجتمع لها الأسبقية في تشغيل وإدارة صيدليات العيادات الخارجية لمستشفيات ومراكز الرعاية الأولية التابعة لوزارة الصحة داخل أروقتها (3 مستشفيات + مستشفى جامعي) ضمن فروعها ال (125) حتى تاريخ هذه المقابلة حيث تقوم بصرف الوصفات الطبية بنظام "وصفتي". كما أن الخدمات المتميزة التي تقدمها صيدليات المجتمع – العديد منها مرجعية- مثل تقديم الاستشارات الدوائية والطب الاتصالي والمتجر الإلكتروني والتوصيل المنزلي زاد من الحاجة إلى توسيع التوزيع الجغرافي.',
    question3 :'3-ما التوزيع الجغرافي للصيدليات؟',
    answer3 :'فيما يلي جدول يوضح التوزيع الجغرافي الحالي لصيدليات المجتمع:',

    // table
    tableHeader1: 'المدينة',
    tableHeader2: 'عدد الفروع',
    tableHeader3: 'النسبة (%)',
    JEDDAH: 'جدة',
    TAIF: 'الطائف',
    MAKKAH: 'مكة',
    RIYADH: 'الرياض',
    JAZAN: 'جازان',
    MADINAH: 'المدينة',
    RABIGH: 'رابغ',
    ALKHARJ: 'الخرج',
    YANBU: 'ينبع',
    ONLINESTORE: 'المتجر الالكتروني',

    question4 :'4-تمتلك الشركة سلسلة كبيرة من الصيدليات في مختلف مناطق المملكة، هل يمكنكم تزويدنا بتفاصيل حول أداء هذه الصيدليات من ناحية الإيرادات وصافي الأرباح ونسب النمو؟',
    answer4 :'حققت الشركة إيرادات بلغت حوالي 367 مليون ريال سعودي وصافي أرباح بلغ حوالي 19مليون ريال سعودي كما في نهاية عام 2022م حيث حققت الشركة نمو في الإيرادات بنسبة 21% ونمو في صافي الأرباح بنسبة 144% عن العام المالي السابق. كما بلغت إيرادات الشركة حوالي 204 مليون ريال سعودي وبلغ صافي الأرباح حوالي 11 مليون ريال سعودي كما في 30 يونيو 2023م، أي بما يتمثل في نمو في الإيرادات بنسبة 19% ونمو في صافي الأرباح بنسبة 66% عن الفترة المالية المماثلة السابقة.',
    question5 :'5-حققت الشركة أرباحاً بـ 11.3 مليون ريال بنهاية النصف الأول من عام 2023م، ما تعليقكم على هذه النتائج؟ وما توقعاتكم لأداء النصف الثاني؟',
    answer5 :'حققت الشركة نمو في صافي الأرباح في عام 2022م بنسبة 144% عن العام المالي السابق، وذلك جراء ضخ سيولة إضافية في الشركة مع دخول مستثمرين جدد، وأيضاً ازدياد معدل عدد الفروع الجديدة عن الذي كانت عليه في الأعوام السابقة قبل ضخ السيولة الإضافية. وبناءً على ذلك تم تحقيق نمو إضافي في الإيرادات وصافي الأرباح خلال النصف الأول من عام 2023م، حيث بلغت أرباح الشركة 11,33 مليون ريال سعودي كما في 30 يونيو 2023م. ومن المتوقع أن تحقق الشـركة إجمالي مبيعات تبلغ 430 مليون ريال سعودي بنهاية عام 2023م وصافي أرباح تبلغ 24 مليون ريال سعودي أي بزيادة بمعدل 25% عن عام 2022م بإذن الله.',
    question6 :'6-ما هو تصور الشركة لمستقبل صناعة الرعاية الصحية في المملكة والتحديات والفرص المتوقعة في هذا القطاع؟',
    answer6 :'منذ الإعلان عن توجه الدولة رعاها الله لتخصيص خدمة الرعاية الصحية، اتخذ القطاع الصحي الخاص خطوات مهمة تتمثل بدخول مستثمرين لإنشاء العديد من المستشفيات والمراكز المتخصصة بالشراكة مع خبرات عالمية ومحلية ذات تجربة واسعة. وفي هذا الصدد، قامت شركة المجتمع الرائدة الطبية بإنشاء مستودع على مساحة 21 ألف متر مربع مجهز بأحدث وسائل التخزين والصـرف لمقابلة الطلب المتزايد على خدمات فروعها التي يجري افتتاحها والتوسع في مناطق جغرافية جديدة. كما أن قيام شركة المجتمع الرائدة الطبية بتشغيل الصيدليات الخارجية لعدد من المستشفيات التابعة لوزارة الصحة ووزارة التعليم العالي في كل من: جدة والطائف وجازان وصبيا ألقى عليها مسؤولية زيادة طاقتها الاستيعابية والتشغيلية وتدريب وتوظيف الكوادر الصحية الوطنية ووضع الخطط المستقبلية كشركة رائدة في تنفيذ برامج التحول الوطني ورؤية 2030 وكأول سلسلة صيدليات مجتمعية حققت مبدأ التكامل مع مستشفيات ومراكز صحية حكومية. وإذا علمنا أن عدد مستشفيات وزارة الصحة تبلغ 284 مستشفى وحوالي 2300 مركز رعاية أولية فيتضح لنا جلياً أن توجه الشركة بعقد شراكات طويلة الأمد مع وزارة الصحة وغيرها من القطاعات الصحية الحكومية هو استثمار ممتاز ذو نظرة مستقبلية واعدة.',
    question7 :'7-ما توقعاتكم لحجم الإيرادات الإضافية والربحية الناتجة من التوسعات الجديدة؟',
    answer7 :'كما تمت الإشارة في الإجابة على الأسئلة رقم(4) ورقم (5) ورقم (6) فإن مجال قطاع الدواء هو مجال واعد، ومن خلال النظرة المتمعنة من إدارة الشـركة التي ترى في الأفق مجالاً رحباً للتوسع الأفقي بافتتاح فروع في مناطق جغرافية جديدة، والتوسع الرأسي بتطوير وادخال أحدث انظمة الادارة والمحاسبة وادارة المخزون حسب المعايير الدولية والتدريب على مهارات البيع، كما وضعت ضمن اولوياتها تطوير وتدريب الكادر الصحي الوطني وتحقيق نسبة التوطين المطلوبة لمهنة "صيدلي" وتوقيع الاتفاقيات في هذا الشأن مع عدد من كليات الصيدلة ووضع برامج التدريب المناسبة لتأهيل الكادر الوطني للعمل في مجال الصيدليات المجتمعية، مما ساهم في رفع مستوى الاداء وانعكس على هيئة قفزات مهمة في رقم المبيعات وزيادة في الربحية. وإجابة على سؤالكم، يُتوقع في نهاية عام 2024م أن تزيد المبيعات عن نصف مليار ريال سعودي وصافي أرباح تتجاوز (27) مليون ريال سعودي.',
    question8 :'8-هل هناك خطط للانتقال إلى السوق الرئيسية؟',
    answer8 :'نعم بالتأكيد، خاصة وأن الشركة تسعى لأن تعزز مكانتها وريادتها في مجال الخدمات الصيدلانية، خاصةً في مجال إدارة المخزون وسلاسل الإمداد والصرف الآمن والرشيد لتوفير الدواء والمستحضرات الصيدلانية للمستفيد.',
  
    ConnectingwithinvestorsRelation:'تواصل مع علاقات المستثمرين',
    Name:'الاسم',
    Email:'البريد الالكتروني',
    CellNo:'رقم الجوال',
    Subject:'عنوان الرسالة',
    Mail:'الرسالة',
    Send:'إرسال',
  },

  medicalJournal: {
    medicalJournalEmagazine : 'المجلة الطبية',
    search : 'بحث',
    Category : 'الاقسام',
    SubCategory : 'الأقسام الفرعية',
    Tags : 'هاشتاق',
  },

  branches:{
    OnTheMap: 'فروعنا على الخريطة',
    branchesWorkingHours: 'أوقات الدوام',
    AdditionalBranchServices: 'خدمات فرع إضافية',
  },

  jobForm:{
    success: 'تم تسجيل البيانات بنجاح',
    goTo: 'للرجوع للصفحة الرئيسية',
    replace1: 'اهلا بك في شركة المجتمع الرائدة الطبية',
    replace2: ' لتعبئة نموذج التوظيف',
    replaceLink: 'اضغط هنا',
    noForm: 'غير متاح استقبال طلبات توظيف',
    EMPLOYMENTAPPLICATIONFORM: 'نموذج طلب التوظيف',
    Pharmaceutical: 'صيدلي',
    NonPharmacist: 'غير صيدلي',
    RequiredJOB: 'الوظيفة المطلوبة',
    WriteTheJob: 'اكتب الوظيفة',
    mentionedInPassport: 'الاسم (حسب جواز السفر)',
    DOB: 'تاريخ الميلاد',
    Address: 'محل الاقامة',
    IDno: 'رقم الهوية',
    Nationality: 'الجنسية',
    ExpiryDate: 'تاريخ الانتهاء',
    Religion: 'الديانة',
    AttitudeTowardsRecruitment: 'الموقف من التجنيد (خاص بالصيادلة المصريين)',
    finalExemtion: 'اعفاء نهائي',
    temparayExemtion: 'اعفاء مؤقت',
    CompletedService: 'أدى الخدمة',
    Maritalstatus: 'الحالة الاجتماعية',
    NoOfChildren: 'عدد الابناء',
    HomeAddress: 'عنوان السكن',
    MobileNo: 'رقم الهاتف( الرجاء ادخال الأرقام بالإنجليزية )',
    Profession: 'التخصص',
    EmailID: 'البريد الالكتروني',
    Qualification: 'المؤهلات العلمية',
    SchoolInstitutionUniversity: 'المدرسة/المعهد/الجامعة',
    YearOfGraduation: 'سنة الحصول عليها',
    Percentage: 'المعدل/التقدير',
    YearsOfExperience: 'عدد الخبرات العملية: ( عدد الوظائف التي عملت بها):',
    Workplace: 'مكان العمل',
    TheCityWhereYouWork: 'المدينة التي بها العمل',
    JobTitle: 'المسمى الوظيفي',
    NumberOfYearsOfWork: 'عدد سنوات العمل',
    ReasonsForLeavingWork: 'أسباب ترك العمل',
    JobRequirements: 'متطلبات الوظيفة',
    CourseName1: 'اسم الدورة 1',
    CourseName2: 'اسم الدورة 2',
    ThePartyInChargeOfIt: 'الجهة القائمة عليها',
    DidYouUploadYourDocuments: 'هل قدمت أوراقك (شهادة التخرج وشهادة الخبرة) للحصول على شهادة الداتافلو',
    DidYouAdmitTo: 'هل تقدمت لإختبار البرومترك (بيرسون فيو) ',
    DoYouHaveTheSCFHS: 'هل تحمل شهادة التصنيف المهني من الهيئة السعودية للتخصصات الصحية',
    DoYouHaveDrivingLicense: 'هل تحمل رخصة قيادة ؟',
    AreYouAbleToDrive: 'هل تجيد قيادة السيارات ؟',
    EnglishLevelProficiency: 'درجة اجادة اللغة الانجليزية',
    KindlyAttachedPassport: 'ارفاق صورة الهوية او جواز السفر',
    KindlyAttachedCV: 'ارفاق السيرة الذاتية',
    KindlyattachedBachelorsdegree: 'ارفاق شهادة البكالوريوس',
    KindlyAttachedSyndicateOfPharmacy: 'ارفق كارنية نقابة الصيادلة',
    KindlyAttachedLicenseForPractisingProfession: 'ارفاق ترخيص مزاولة المهنة بوزارة الصحة بدولتك',
    ExperienceCertificate1: 'شهادات الخبرة 1',
    ExperienceCertificate2: 'شهادات الخبرة 2',
    ExperienceCertificate3: 'شهادات الخبرة 3',
    AttachACopyOfYourTranscript: 'ارفاق صورة بيان الدرجات',
    professionInKSA: 'ارفق صورة من ترخيص مزاولة المهنة في المملكة العربية السعودية',
    TrainingCourses: 'الدورات التدريبية',
    
  }
  
}